<div id="admin_setting" class="admin-page">
  <h3 class="title is-3">Pengaturan Voucher</h3>
  <div class="columns is-multiline text-left mb-6">
    <div class="column is-12">
      <div class="field">
        <label class="label">Kode Voucher</label>
        <div class="control">
          <input class="input" type="text" v-model="inputData.id" />
        </div>
        <p class="help is-danger is-italic">Voucher yang dibuat tidak dapat diedit lagi dan tidak dapat duplikat</p>
      </div>
    </div>
    <div class="column">
      <button class="button is-success" @click="doSave()">Buat Voucher</button>
    </div>
  </div>

  <div class="mt-6">
    <Pagination :pagination="pagination" @paginate:goto="updatePage">
      <div class="table-container">
        <table
          class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-left"
        >
          <thead>
            <tr>
              <th>Aksi</th>
              <th>Voucher</th>
              <th>Hadiah Dimenangkan</th>
              <th>Dibuat Oleh</th>
              <th>Dibuat Pada</th>
              <th>Diupdate Pada</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Aksi</th>
              <th>Voucher</th>
              <th>Hadiah Dimenangkan</th>
              <th>Dibuat Oleh</th>
              <th>Dibuat Pada</th>
              <th>Diupdate Pada</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(data, index) in tableData" :key="index">
              <td class="has-text-centered">
                <button class="button is-success mr-1" @click="doEdit(data)">
                  Edit
                </button>
                <button class="button is-danger" @click="doDelete(data)">
                  Hapus
                </button>
              </td>
              <td>{{data.id}}</td>
              <td>{{data.prize}}</td>
              <td>{{data.createdBy}}</td>
              <td>
                {{new Date(data.createdAt).toLocaleString('en-US', { weekday:
                'long', day: 'numeric', month: 'long', year: 'numeric', hour:
                '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC'
                })}}
              </td>
              <td>
                {{new Date(data.updatedAt).toLocaleString('en-US', { weekday:
                'long', day: 'numeric', month: 'long', year: 'numeric', hour:
                '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC'
                })}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Pagination>
  </div>
</div>
