import { ref, onMounted } from "vue";
import { handleError } from "@/utils/errorHandler";
import VoucherService from "@/services/voucher_service";
import Pagination from "@/components/PaginationTable.vue";
import Datepicker from "vue3-datepicker";

export default {
  name: "Game Admin",
  components: { Pagination, Datepicker },
  setup(props, context) {
    const inputData = ref({
      id: undefined,
    });

    const tableData = ref([
      {
        id: undefined,
        prize: undefined,
        createdBy: undefined,
        createdAt: undefined,
        updatedAt: undefined,
      },
    ]);

    const pagination = ref({
      page: 1,
      limit: 40,
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
    });

    const updatePage = (value) => {
      pagination.value.page = value;
      fetchData();
    };

    const fetchData = async () => {
      try {
        let response = await VoucherService.getAll(
          pagination.value.page,
          pagination.value.limit
        );
        tableData.value = response.data.data.rows;
        pagination.value.firstPage = response.data.data.firstPage;
        pagination.value.lastPage = response.data.data.lastPage;
        pagination.value.currentPage = response.data.data.currentPage;

        // console.log("TABLE DATA:::", tableData.value);
      } catch (error) {
        handleError(context, error);
      }
    };

    onMounted(async () => {
      fetchData();
    });

    const doEdit = (data) => {
      inputData.value = { ...data };
    };

    const cancelEdit = () => {
      inputData.value.id = undefined;
    };

    const doDelete = async (data) => {
      let confirmation = confirm(
        `Yakin menghapus voucher ${data.id}?\nData yang dihapus tidak dapat dikembalikan`
      );
      if (confirmation) {
        try {
          let response = await VoucherService.deleteData(data.id);
          if (response.data.success) {
            fetchData();
            alert("Data berhasil dihapus");
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const doSave = () => {
      if (!inputData.value.id) {
        alert("Kode Voucher harus diisi");
        return;
      }
      let confirmation = confirm(`Yakin menyimpan data ?`);
      if (confirmation) {
        createNewData();
        fetchData();
      }
    };

    const createNewData = async () => {
      try {
        let response = await VoucherService.createData(inputData.value);
        if (response.data.success) {
          fetchData();
          alert("Data berhasil disimpan");
        }
      } catch (error) {
        if (error.response.data.error == "Validation error") {
          alert("Kode Voucher sudah ada, silahkan coba kode lain");
        }
        handleError(context, error);
      }
    };

    return {
      inputData,
      tableData,
      pagination,
      updatePage,
      doEdit,
      cancelEdit,
      doDelete,
      doSave,
    };
  },
};
