import BaseServices from "./base_services";
const RESOURCE_NAME = "/voucher";

class PasaranServices extends BaseServices {
  constructor(moduleName) {
    super(moduleName);
  }
}

export default new PasaranServices(RESOURCE_NAME);
